<template>
  <span>
    ECL&nbsp;{{ ecl }}&nbsp;
    <span class="icon" v-if="icon">
      <i v-bind:class="['mdi', icon, 'mdi-24px']"></i>
    </span>
  </span>
</template>

<script>
export default {
  name: 'FooterBar',
  props: {
    message: {
      required: true,
    },
  },
  computed: {
    ecl() {
      const { data } = this.message;
      let ecl;
      if (data.statistics) {
        ecl = data.statistics.ecl;
      } else if (data.network && data.network.parameter) {
        ecl = data.network.parameter.ecl;
      } else if (data.network && data.network.nuestats) {
        ecl = data.network.nuestats.ecl;
      } else if (data.network && data.network.statistics) {
        ecl = data.network.statistics.ecl;
      } else if (data.nuestats) {
        ecl = data.nuestats.ecl;
      } else if (data.device && data.device.profile === 'ROVER') {
        return null;
      } else {
        // console.log(message);
        return null;
      }
      if (ecl === 255) return null;
      return ecl;
    },
    icon() {
      switch (this.ecl) {
      case 0:
        return 'mdi-signal-cellular-3';
      case 1:
        return 'mdi-signal-cellular-2';
      case 2:
        return 'mdi-signal-cellular-1';
      default:
        return null;
      }
      // signal-cellular-outline
    },
  },
};
</script>
<style scoped>
span.icon i.mdi-signal-cellular-1 {
  color: red;
}
span.icon i.mdi-signal-cellular-2 {
  color: orange;
}
span.icon i.mdi-signal-cellular-3 {
  color: green;
}
</style>
